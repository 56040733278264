import { useTheme } from "vuetify";
import { useLocalStorage } from "@vueuse/core";
import type { WritableComputedRef } from "vue";
import { match } from "../utils/common";

type ThemeName = "light" | "dark";
type AvailableTheme = ThemeName | "system";
const themes: AvailableTheme[] = ["light", "dark", "system"];

export const useAppTheme = () => {
  const themeConfig: AvailableTheme = "light" as AvailableTheme;

  // const appConfig = useAppConfig();
  const vVuetify = useTheme();
  const vStorage = useLocalStorage<AvailableTheme>("theme", themeConfig);

  const getCurrentSystemTheme = (): ThemeName => {
    if (process.server) {
      if (themeConfig == "system") return "light";
      return themeConfig;
    }
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    return darkThemeMq.matches ? "dark" : "light";
  };

  const systemTheme = ref<ThemeName>(getCurrentSystemTheme());

  const computedTheme = computed<ThemeName>(() => {
    if (vStorage.value == "system") {
      return systemTheme.value;
    }
    return vStorage.value;
  });

  const setTheme = (value: AvailableTheme) => {
    vStorage.value = value;
    vVuetify.global.name.value = computedTheme.value;
  };

  const setDark = (dark = true) =>
    dark ? setTheme("dark") : setTheme("light");
  const setSystem = () => setTheme("system");

  const toggle = () => {
    let next = themes.indexOf(vStorage.value) + 1;
    if (next >= themes.length) {
      next = 0;
    }
    setTheme(themes[next]);
  };

  if (!process.server) {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        systemTheme.value = event.matches ? "dark" : "light";
      });
  }

  const _theme: WritableComputedRef<AvailableTheme> = computed({
    get: () => vStorage.value,
    set: (value) => setTheme(value),
  });

  const isDark = computed(() => computedTheme.value === "dark");
  const icon = computed(() =>
    match(vStorage.value, {
      light: "material-symbols:light-mode-rounded",
      dark: "material-symbols:mode-night",
      system: "material-symbols:assistant-device-rounded",
    })
  );

  vVuetify.global.name.value = computedTheme.value;

  return {
    toggle,
    icon,
    theme: _theme,
    themeValue: readonly(vStorage),
    isDark,
    setTheme,
    setDark,
    setSystem,
  };
};
