<template>
  <span>v{{ version }}</span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppVersion",
  setup() {
    const config = useRuntimeConfig();

    return {
      version: config.public.app.version,
    };
  },
});
</script>
