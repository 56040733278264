<template>
  <v-card min-width="270" class="pa-4 border" rounded="lg">
    <div class="font-bold">Notifications</div>
    <v-alert class="mt-4 text-3.5"
      >Alerts about your workspace and account will show here</v-alert
    >
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppNotifications",
});
</script>
