<template>
  <div v-if="isTrial" class="bg-red w-full pa-4 flex-align">
    <span
      >Your trial expires in
      {{ trialDaysLeft > 1 ? trialDaysLeft + " days" : "a day" }}.</span
    >
    <v-spacer />
    <v-btn :to="{ name: 'access-pricing' }"> Select a Plan </v-btn>
  </div>
</template>

<script>
export default {
  setup() {
    const { isTrial, trialDaysLeft } = useAccountStatus();
    return {
      isTrial,
      trialDaysLeft,
    };
  },
};
</script>
