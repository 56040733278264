<template>
  <v-app-bar id="app-header" class="pr-3 md:pl-3 bg-black" height="55" flat>
    <v-btn
      @click="toggle"
      class="md:hidden!"
      size="small"
      rounded="lg"
      tile
      icon
    >
      <Icon name="material-symbols:menu" size="25" />
    </v-btn>
    <span class="hidden md:block md:w-220px">
      <Workspace />
    </span>
    <router-link
      to="/"
      class="relative flex-align pa-1 md:hidden decoration-none text-inherit"
    >
      <Logo size="25" />
      <span class="ml-1">Dashboard</span>
    </router-link>
    <v-spacer></v-spacer>

    <v-btn class="mr-2" variant="tonal" size="35" rounded="lg" tile icon>
      <Icon name="heroicons:bell" size="25" />
      <v-menu
        activator="parent"
        location="bottom"
        transition="slide-y-transition"
        offset="15"
      >
        <AppNotifications />
      </v-menu>
    </v-btn>

    <v-btn
      :to="{ name: 'settings' }"
      class="mr-2 lt-md:hidden!"
      variant="tonal"
      size="35"
      rounded="lg"
      tile
      icon
    >
      <Icon name="clarity:settings-line" size="25" />
    </v-btn>

    <UserAvatar
      :name="userName"
      color="primary"
      size="35"
      rounded="lg"
      class="cursor-pointer"
      tile
    >
      <v-menu
        activator="parent"
        location="bottom"
        transition="slide-y-transition"
        offset="15"
      >
        <ProfileDropdown />
      </v-menu>
    </UserAvatar>

    <!-- <v-btn variant="tonal" size="35" rounded="lg" tile icon="add">
    </v-btn> -->
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAppNavStore } from "../stores/app-nav";

export default defineComponent({
  name: "AppHeader",
  setup() {
    const { toggle } = useAppNavStore();
    const { user } = useAuth();

    const userName = computed(() => user.value?.name);

    return {
      toggle,
      userName,
    };
  },
});
</script>
