<template>
  <v-list-item :title="item.name" :to="item.route" rounded="lg" class="group">
    <template #prepend="props">
      <slot name="prepend" v-bind="props">
        <AppIcon
          :name="icon(props.isActive)"
          size="16"
          class="mr-2"
          :class="{ 'opacity-70': !props.isActive }"
        />
      </slot>
    </template>
    <template #append>
      <span
        class="transition opacity-0 group-hover:opacity-100! h-full flex-align"
      >
        <v-btn
          v-if="item.pinned"
          @click.prevent.stop="unpin"
          variant="plain"
          size="25"
          icon
        >
          <v-icon size="20">close</v-icon>
        </v-btn>
        <v-btn v-else @click.prevent.stop="pin" variant="plain" size="25" icon>
          <AppIcon name="carbon:pin" size="18" />
        </v-btn>
      </span>
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { useAppNavShortcutsStore } from "../../stores/app-nav";
import type { AppShortcutNavItem } from "./types";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<AppShortcutNavItem>,
      required: true,
    },
  },
  setup(props) {
    const store = useAppNavShortcutsStore();

    const pin = () => {
      store.pinNav(props.item);
    };
    const unpin = () => {
      store.forgetNav(props.item);
    };

    return {
      pin,
      unpin,
    };
  },
  methods: {
    icon(active: boolean) {
      if (this.item.pinned) {
        return active ? "carbon:pin" : "carbon:pin-filled";
      }
      return active ? "heroicons:clock" : "heroicons:clock-16-solid";
    },
  },
});
</script>
