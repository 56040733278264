<template>
  <div v-if="pendingContract" class="bg-red w-full pa-4 text-center">
    Your plan contract is pending approval
  </div>
</template>

<script>
export default {
  setup() {
    const { pendingContract } = useAccountStatus();
    return {
      pendingContract,
    };
  },
};
</script>
