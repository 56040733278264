<template>
  <v-app id="app">
    <NoJs />
    <AppHeader />
    <AppNavigation />

    <v-main>
      <slot></slot>
    </v-main>

    <v-footer class="pa-0 relative" app>
      <TrialStatus />
      <PlanStatus />
    </v-footer>

    <!-- <LoadingIndicator /> -->
    <NuxtLoadingIndicator color="#5c6bc0" />
    <ConfirmDialog />
    <KeyboardShortcutsHelp />
    <Notifications />
    <!-- <AcceptTerms /> -->
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TrialStatus from "~/components/account-alerts/TrialStatus.vue";
import PlanStatus from "~/components/account-alerts/PlanStatus.vue";
import AcceptTerms from "~/components/account-alerts/AcceptTerms.vue";

export default defineComponent({
  components: { TrialStatus, PlanStatus, AcceptTerms },
});
</script>
