<template>
  <v-navigation-drawer v-model="open" :color="color" id="app-nav" width="230">
    <template #prepend>
      <div class="md:hidden">
        <div class="pa-2">
          <Workspace />
        </div>
        <v-divider />
      </div>
    </template>

    <v-list
      class="mt-2 px-2"
      :lines="false"
      density="compact"
      open-strategy="single"
      active-strategy="independent"
      select-strategy="single-independent"
      nav
    >
      <template v-for="(item, i) in nav" :key="`item-${i}`">
        <NavGroup v-if="isGroup(item)" :item="item" class="root-nav-group" />
        <NavItem v-else :item="item" />
      </template>
    </v-list>

    <div v-if="shortcuts.length" class="mt-2">
      <div class="caption mx-4">Shortcuts</div>
      <v-list :lines="false" density="compact" nav>
        <ShortcutItem
          v-for="(item, i) in shortcuts"
          :key="`sc-${i}`"
          :item="item"
        />
      </v-list>
    </div>

    <template #append>
      <v-divider />

      <div class="my-1 mx-2 flex-align text-3">
        <ThemeSwitcherBtn size="30" />
        <span class="ml-4 mr-2">Dashboard</span>
        <AppVersion />
      </div>

      <v-divider />

      <v-list-item
        rounded="lg"
        class="my-1 mx-2 px-2"
        :to="{ name: 'settings' }"
        exact
      >
        <template #prepend="{ isActive }">
          <Icon
            :name="
              isActive ? 'clarity:settings-solid' : 'clarity:settings-line'
            "
            size="20"
            class="mr-2"
          />
        </template>
        <template #title>
          <span class="text-3">Settings</span>
        </template>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { storeToRefs } from "pinia";
import { useTheme, useDisplay } from "vuetify";
import type { RouteLocationRaw } from "vue-router";
import { useAppNavStore, useAppNavShortcutsStore } from "../stores/app-nav";
import {
  isGroup,
  NavItem,
  NavGroup,
  ShortcutItem,
} from "../private/app-navigation";
import { omit } from "lodash";

export default defineComponent({
  name: "AppNavigation",
  components: { NavItem, NavGroup, ShortcutItem },
  setup() {
    const store = useAppNavStore();
    const shortcutsStore = useAppNavShortcutsStore();
    const { open } = storeToRefs(store);
    const { shortcuts } = storeToRefs(shortcutsStore);
    const theme = useTheme();
    const { mobile } = useDisplay();

    const config = useAppConfig();
    const nav = computed(() => {
      if (mobile.value) {
        return config.app.nav.map((item) => {
          if (item.route && item.items) {
            return {
              ...omit(item, ["route"]),
              items: [
                {
                  name: item.name,
                  route: item.route,
                },
                ...item.items,
              ],
            };
          }

          return item;
        });
      }

      return config.app.nav;
    });
    const color = computed(() =>
      theme.current.value.dark ? undefined : "grey-lighten-3"
    );

    return {
      open,
      nav,
      color,
      shortcuts,
    };
  },
  methods: {
    routeTo(to: RouteLocationRaw) {
      this.$router.push(to);
    },
    isGroup,
  },
});
</script>

<style lang="scss">
.root-nav-group {
  .nav-group-item .v-list-item-title {
    @apply pl-4;
  }
  .nav-group-item::before,
  .nav-group-item::after {
    display: block;
    border: none;

    opacity: 0;
    content: "";
    position: absolute;
    // top: -0.12rem;
    // bottom: -0.35rem;
    top: -0.35rem;
    bottom: -0.35rem;
    left: 0.5rem;
    right: 0;
    width: 1.3125rem;
    border-radius: 0;
    pointer-events: none;
  }

  .nav-group-item::after {
    top: 0rem;
    height: 2rem;
  }

  .nav-group-item:has(~ .v-list-item--active)::before,
  .nav-group-item.v-list-item--active::before,
  .nav-group-item.v-list-item--active::after {
    opacity: 1;
    z-index: 1;
  }

  .nav-group-item:hover::after {
    opacity: 0.8;
  }

  .nav-group-item.v-list-item--active::before,
  .nav-group-item:has(~ .v-list-item--active)::before {
    background: url(data:image/svg+xml,%3Csvg%20width%3D%2221%22%20height%3D%2228%22%20viewBox%3D%220%200%2021%2028%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%229%22%20width%3D%221.5%22%20height%3D%2228%22%20fill%3D%22%23B5B5B5%22%2F%3E%3C%2Fsvg%3E);
  }

  .nav-group-item.v-list-item--active::before {
    top: -0.35rem;
    bottom: 1.3rem;
  }

  .nav-group-item.v-list-item--active::after,
  .nav-group-item:not(.v-list-item--active):hover::after {
    top: 0.12rem;
    bottom: 0rem;
    background-repeat: no-repeat !important;
    background: url(data:image/svg+xml,%3Csvg%20width%3D%2721%27%20height%3D%2728%27%20viewBox%3D%270%200%2021%2028%27%20fill%3D%27none%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M19%2014.25H19.75V15.75H19V14.25ZM10.077%2013.362L10.7452%2013.0215V13.0215L10.077%2013.362ZM11.388%2014.673L11.7285%2014.0048H11.7285L11.388%2014.673ZM10.5%200V10.2H9V0H10.5ZM14.55%2014.25H19V15.75H14.55V14.25ZM10.5%2010.2C10.5%2011.0525%2010.5006%2011.6467%2010.5384%2012.1093C10.5755%2012.5632%2010.6446%2012.824%2010.7452%2013.0215L9.40873%2013.7025C9.18239%2013.2582%209.08803%2012.7781%209.04336%2012.2315C8.99942%2011.6936%209%2011.0277%209%2010.2H10.5ZM14.55%2015.75C13.7223%2015.75%2013.0564%2015.7506%2012.5185%2015.7066C11.9719%2015.662%2011.4918%2015.5676%2011.0475%2015.3413L11.7285%2014.0048C11.926%2014.1054%2012.1868%2014.1745%2012.6407%2014.2116C13.1033%2014.2494%2013.6975%2014.25%2014.55%2014.25V15.75ZM10.7452%2013.0215C10.9609%2013.4448%2011.3052%2013.7891%2011.7285%2014.0048L11.0475%2015.3413C10.3419%2014.9817%209.76825%2014.4081%209.40873%2013.7025L10.7452%2013.0215Z%27%20fill%3D%27%23B5B5B5%27/%3E%3Cpath%20d%3D%27M17%2012L20%2015L17%2018%27%20stroke%3D%27%23B5B5B5%27%20stroke-width%3D%271.5%27%20stroke-linecap%3D%27round%27%20stroke-linejoin%3D%27round%27/%3E%3C/svg%3E%0A);
  }
}
</style>
