<template>
  <v-btn @click="toggle" :size="size" icon>
    <Icon :name="icon" />
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThemeSwitcherBtn",
  props: {
    size: [Number, String],
  },
  setup() {
    const { toggle, icon } = useAppTheme();

    return {
      toggle,
      icon,
    };
  },
});
</script>
